export const Constants = {
    formTypes: {
        delete: 'DELETE',
        edit: 'EDIT',
        export: 'EXPORT',
        import: 'IMPORT',
        insert: 'INSERT',
        paste: 'PASTE',
        save: 'save',
    },
    modifiedDataType: {
        add: 'add',
        cut: 'cut',
        delete: 'delete',
    },
    weekdays: {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 7,
    },
    days: {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        7: "Sun",
    },
    programFields: [
        'dayOfWeek',
        'startTime',
        'endTime',
        'type',
        'blockReference',
        'genre',
        'maxCount',
        'availableDuration',
        'seriesID',
        'seriesName',
        'seasonNo',
        'titleGroupID',
        'titleGroupName',
        'layout',
        'sequential',
        'link',
        'blockName',
    ],
    gradingFields: [
        'dayOfWeek',
        'startTime',
        'endTime',
        // 'grade',
        'value',
    ],
    minutageFields: [
        'dayOfWeek',
        'startTime',
        'endTime',
        'value',
        // 'minutage',
    ],
    fieldConstraints: {
        week: {
            min: 1,
            max: 52,
            type: 'number',
            optional: true,
        },
        dayOfWeek: {
            min: 1,
            max: 7,
            type: 'number',
            optional: false,
        },
        startTime: {
            type: 'string',
            format: 'HH:mm:ss',
            optional: false,
        },
        endTime: {
            type: 'string',
            format: 'HH:mm:ss',
            optional: false,
        },
        grade: {
            min: 1,
            max: 10,
            type: 'number',
            optional: false,
        },
        minutage: {
            type: 'number',
            optional: false,
        },
        imported: {
            type: 'boolean',
            optional: true,
        },
        result: {
            type: 'string',
            optional: true,
        },
        type: {
            type: 'string',
            options: ['Movies', 'Series', 'Placeholder', 'TitleGroup', 'Repeat'],
            optional: false,
        },
        genre: {
            type: 'string',
            optional: true,
        },
        maxCount: {
            type: 'number',
            optional: true,
            pattern: /^\d+$/,
        },
        availableDuration: {
            type: 'number',
            optional: true,
            pattern: /^\d+$/,
        },
        seriesID: {
            type: 'number',
            optional: true,
            pattern: /^\d+$/,
        },
        seriesName: {
            type: 'string',
            optional: true,
        },
        seasonNo: {
            type: 'number',
            optional: true,
            pattern: /^\d+$/,
        },
        titleGroupID: {
            type: 'number',
            optional: true,
            pattern: /^\d+$/,
        },
        titleGroupName: {
            type: 'string',
            optional: true,
        },
        layout: {
            type: 'string',
            optional: true,
            options: ['Sequential', 'Shuffle'],
        },
        sequential: {
            type: 'boolean',
            optional: true,
        },
        link: {
            type: 'boolean',
            optional: true,
        },
        blockReference: {
            type: 'string',
            optional: true,
            pattern: /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun)([01][0-9]|2[0-3]):([0-5][0-9])$/,
        },
        blockName: {
            type: 'string',
            optional: true,
        },
    }

}