export const OpTyphography = ({ text, required}) => {
  return (
    <div style={{
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
      textAlign: "left",
      color: '#6D6D73',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>
      {text}
      {required && (<strong style={{color: '#6D6D73', marginLeft: '4px'}}>*</strong>)}
    </div>
  );
}