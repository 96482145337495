import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ImportSlots.module.css';

export default function ImportConfirmModalComponent({
  actionButtons,
  closeModal,
  handleOverwrite,
  handleCreate,
  headlineMessage,
  message,
}) {
  return (
    <Modal show={true} centered size="md">
      <div className={styles.body} style={{ textAlign: 'center' }}>
        <div>
          <p className={styles.description}>
            {headlineMessage ?? 'This schedule you are importing contains slots with overlapping time and date on an existing slot.'}
          </p>

          <p className={styles.confirmationText}>
            {message ?? 'Do you want to overwrite the existing slot or create a new one?'}
          </p>
        </div>

        <div style={{ marginTop: '30px' }}>
          <button
            className={`${styles.cancelButton} ${styles.marginRight10}`}
            onClick={closeModal}
            style={{ marginRight: '0' }}
            type="button"
          >
            Cancel
          </button>
          {actionButtons ?  actionButtons.map(action => (
            <button
              className={`${styles.saveButton} ${styles.marginRight10}`}
              onClick={action.onClick}
              type="button"
            >{action.name}</button>
          )) : (
            <React.Fragment>
              <button
                className={`${styles.saveButton} ${styles.marginRight10}`}
                onClick={handleOverwrite}
                type="button"
              >
                Overwrite
              </button>
              <button
                className={styles.saveButton}
                onClick={handleCreate}
                type="button"
              >
                Create
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
}
