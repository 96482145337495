import moment from "moment";
import { Constants } from "../helper/constants";

export const reconstructBlockName = (blockName) => {
    let name = blockName;
    //Not undefined nor empty string
    if (blockName && blockName.length > 0) {
        // If valid blockname format
        if (!isValidBlockame(blockName)) {
            const deconstructedTime = blockName.split(":");
            // if blockname contains 00:00:00 format instead of 00:00
            if (deconstructedTime.length === 3) {
                let temp = `${deconstructedTime[0]}:${deconstructedTime[1]}`
                name = `${temp.slice(0, 3)}${temp.slice(-5)}`;
            } else {
                // From Mon108:00 to Mon08:00
                //Ensures that only the day and time is returned
                const removedWeek = `${blockName.slice(0, 3)}${blockName.slice(-5)}`
                name = removedWeek;
            }
        }
    }
    return name;
}

// Function to get all linked blocks
// Returns array of schedules
export const getAllLinkedBlocks = (selected, data, type) => {
    let blockReference = reconstructBlockName(selected.BlockName);
    let linkedBlocks = []
    let onlySeriesAndTitleGroups = []

    if (data && data.length > 0) {
        linkedBlocks = data.filter((block) => {
            if (selected.Id !== block.id
                && reconstructBlockName(block.blockReference) === blockReference
                && ((block.type === "Series"
                    || block.type === "TitleGroup"))) {
                blockReference = reconstructBlockName(block.blockName);
                return block;
            }
        })
    }
    onlySeriesAndTitleGroups = linkedBlocks.filter((block) => ((block.type === "Series"
        || block.type === "TitleGroup")
        && type === "Series"
        || type === "TitleGroup"))

    if (onlySeriesAndTitleGroups.length) {
        return onlySeriesAndTitleGroups;
    } else return []
}

// Function that generated block names by given start time (String) and day of week (number)
// day: number
// time: string
// Returns string
export const getBlockName = (dayOfWeek, time) => {
    const { days, weekdays } = Constants;
    let day = '';
    let hhmm = '';
    // Validate if time passed is of type string && "00:00:00" format
    if (!(typeof time === 'string' && moment(time, 'HH:mm:ss').isValid)) {
        hhmm = moment(time, 'HH:mm').format('HH:mm')
    } else {
        hhmm = `${time.split(":")[0]}:${time.split(":")[1]}`
    }
    if (!(typeof dayOfWeek === 'number')) {
        day = weekdays[dayOfWeek]
    } else {
        day = days[dayOfWeek]
    }
    return `${day}${hhmm}`
}

// Returns new block reference based off a new start time
export const getNewBlockReference = (oldBlockReference, newStartTime) => {
    if (oldBlockReference?.length) {
        const day = oldBlockReference.slice(0, 3);
        const hhmmFormat = moment(newStartTime, 'HH:mm').format('HH:mm');
        return `${day}${hhmmFormat}`
    } else return oldBlockReference
}

// Validates if blockname is in format Mon00:00
// Returns boolean value of true or false
export const isValidBlockame = (blockname) => {
    const pattern = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun)([01][0-9]|2[0-3]):([0-5][0-9])$/;
    return pattern.test(blockname);
}

// Validates time if in HH:mm:ss format
// Returns boolean value of true or false
export const isValidTimeString = (time) => {
    return moment(time, 'HH:mm:ss').isValid();
}

// Removal of an array of schedules from data
// Passing an arr as parameter
// Returns data without the passed arr
export const eliminateFromData = (arr, data) => {
    const filteredData = data.filter((event) => {
        const found = arr.find((o) => event.id === o.id)
        if (!found) {
            return event;
        }
    })
    return filteredData;
}


// Function that updates data with the passed array of schedules
// Returns updated data
export const updateMultipleData = (arr, data) => {
    const updatedData = data.map((item) => {
        const found = arr.find((o) => item.id === o.id)
        if (found) {
            return found;
        }
        else { return item; }
    })

    return updatedData;
}