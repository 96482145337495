import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import PropTypes from 'prop-types';
import './NumericTextbox.css';

export const NumericTextbox = (props) => {
  const {
    cssClass = "e-op2mise-numeric-textbox",
    enabled,
    format,
    label = '',
    max,
    min,
    onChange,
    showSpinButton,
    value,
    ...rest
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        // marginRight: '10px',
      }}
    >
      <div
        style={{
          fontSize: '14px',
          color: '#6D6D73',
          marginBottom: '5px',
          marginRight: '10px',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </div>
      <NumericTextBoxComponent
        format={format}
        value={value}
        min={min}
        max={max}
        showSpinButton={showSpinButton}
        onChange={onChange}
        enabled={enabled}
        cssClass={cssClass}
        {...rest}
      />
    </div>
  );
};

NumericTextbox.propTypes = {
  cssClass: PropTypes.string,
  enabled: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  showSpinButton: PropTypes.bool,
  value: PropTypes.number,
};