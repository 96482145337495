import React, { useState, useRef, useEffect } from 'react';
import ScheduleService from 'components/schedule/ScheduleService';
import DateService from 'core/date.service';
import AuthService from 'core/auth.service';
import ImportFileModalComponent from 'components/schedule/react-scheduler/forms/import/ImportFileModalComponent';
import ImportLoadingModalComponent from 'components/schedule/react-scheduler/forms/import/ImportLoadingModalComponent';
import ImportConfirmModalComponent from 'components/schedule/react-scheduler/forms/import/ImportConfirmModalComponent';
import { queryParams } from '@syncfusion/ej2-base';
import ImportConfirmationModalComponent from 'components/channel-profiles/business-rules/modals/ImportConfirmationModalComponent';
import ImportResultModalComponent from './ImportResultModalComponent';
import styles from '../../schedule/react-scheduler/forms/import/ImportSlots.module.css';
import moment from 'moment';
import XLSX from 'sheetjs-style';
import { Constants } from './../helper/constants';
import { getBlockName } from '../utils/architecture.utils';

export function ImportComponent({
  closeModal,
  setScheduleData,
  scheduledata = [],
  clockStart = 0,
  scheduleInfo,
  schedulerData,
  setIsDirty,
  sampleTemplate,
  handleOnImportApi,
  importResultColumns,
  importResultFileTitle,
  getUpdatedList,
  setSchedules,
  titleGroupList,
  seriesList,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [headerData, setHeaderData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedList, setUploadedList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [uploadedSlots, setUploadedSlots] = useState([]);
  const { channelId } = { ...scheduleInfo };
  const { schedules, period } = { ...schedulerData };
  const {
    programFields,
    gradingFields,
    minutageFields,
    fieldConstraints
  } = Constants;
  const [isLoading, setIsLoading] = useState(false);
  const loadingHeaderComponent = (
    <div className={styles.divHeader}>
      Importing and validating slots from{' '}
      <b>{selectedFile && selectedFile.name}</b>
    </div>
  );

  const isInvalid = (value) => {
    return (
      value === null || value === undefined || value === 0 || value.length === 0
    );
  };

  const architectureTab = sampleTemplate.includes('grade')
    ? 'Grading'
    : sampleTemplate.includes('minutage')
      ? 'Minutage'
      : 'Program';

  // Only for Grading and Minutage
  const cleanOutInvalidSlots = (data) => {
    data.map((schedule) => {
      if (sampleTemplate.includes('grade')) {
        if (isInvalid(schedule.grade)) {
          schedule.imported = false;
          schedule.result = 'Invalid grade input. Must be between 1 and 10';
        }
      } else if (sampleTemplate.includes('minutage')) {
        if (isInvalid(schedule.minutage)) {
          schedule.imported = false;
          schedule.result = 'Invalid minutage input.';
        }
      }
    });
  };
  const columnHeaders = architectureTab === 'Program' ? programFields
    : architectureTab === 'Grading' ? gradingFields : minutageFields;

  const validateImportedFile = (data, importResultColumns) => {
    let lowerCaseHeaders = columnHeaders.map((header) => header.toLowerCase());
    let validColumns = [];
    let importedColumnFields = data.length > 0 ? Object.keys(data[0]).map((header) => header.toLocaleLowerCase()) : [];
    if (importedColumnFields.length > 0) {
      validColumns = importedColumnFields.map((column) => {
        return lowerCaseHeaders.includes(column)
      }).some((column) => column === false);
    }
    return !validColumns;
  }

  const retrieveTitleGroups = (schedule) => {
    let found = [];
    if (schedule.titleGroupName) {
      found = titleGroupList.find((group) => group.name === schedule.titleGroupName)
    }
    return found;
  }

  const retrieveSeries = (schedule) => {
    let found = [];
    if (schedule.seriesName) {
      found = seriesList.find((item) => item.name === schedule.seriesName)
    }
    return found;
  }

  const validateRows = (data) => {
    let isOverlap = false;
    const hasStartEndWhitespace = /^[\s]+|[\s]+$/g;
    data.forEach((schedule) => {
      columnHeaders.map((field) => {
        const value = schedule[field];
        const constraint = fieldConstraints[field];
        if (field !== 'value' && schedule.import) {
          switch (constraint?.type) {
            case 'number':
              if (value || value === 0) {
                if (typeof value == 'number') {

                  if (!value && !fieldConstraints[field].optional) {
                    schedule.result = `${field} is a required field and must not be empty.`
                    schedule.imported = false;
                  }

                  if (constraint?.min || constraint?.max) {
                    if (value < constraint?.min || value > constraint?.max) {
                      schedule.imported = false;
                      schedule.result = `Invalid ${field} input of ${value}. Must be between ${constraint?.min} and ${constraint?.max}`;
                    }
                  }
                  if (constraint?.pattern) {
                    if (!constraint.pattern.test(value) && value) {
                      schedule.imported = false;
                      schedule.result = `Invalid ${field} input of ${value}. Must be a number.`;
                    }
                  }
                } else {
                  schedule.imported = false;
                  schedule.result = `Invalid ${field} input of ${value}. Must be a number`;
                }
              } else {
                if (!fieldConstraints[field].optional) {
                  schedule.result = `${field} is a required field and must not be empty.`
                  schedule.imported = false;
                }
              }
              break;
            case 'float':
              if (value) {
                if (typeof value !== 'float') {
                  schedule.imported = false;
                  schedule.result = `Invalid ${field} input of ${value}. Must be a float`;
                }
              } else {
                if (!fieldConstraints[field].optional) {
                  schedule.result = `${field} is a required field and must not be empty.`
                  schedule.imported = false;
                }
              }
              break;
            case 'string':
              if (value) {
                if (typeof value === 'string') {
                  if (hasStartEndWhitespace.test(value)) {
                    schedule.imported = false;
                    schedule.result = `Invalid ${field} input of ${value}. Please remove extra whitespace at either start or end of the text.`;
                  }

                  if (field === 'type' && value === 'Repeat') {
                    if (!schedule.blockReference) {
                      schedule.imported = false;
                      schedule.result = `Missing blockReference required for ${field} input of ${value}.`;
                    }
                    if (schedule.blockReference && !schedule.link) {
                      schedule.imported = false;
                      schedule.result = `Link must be set true for values of type 'Repeat'`;
                    }
                  }

                  if (constraint?.options) {
                    if (!constraint?.options.includes(value?.trim())) {
                      schedule.imported = false;
                      schedule.result = `Invalid ${field} input of ${value}. Must be one of ${constraint?.options.join(', ')}.`;
                    } else {
                      if (hasStartEndWhitespace.test(value)) {
                        schedule.imported = false;
                        schedule.result = `Invalid ${field} input of ${value}. Please remove extra whitespace at either start or end of the text.`;
                      }
                    }
                  }

                  if (constraint?.format) {
                    if (constraint?.format === "HH:mm:ss" && !moment(value, 'HH:mm:ss').isValid()) {
                      schedule.imported = false;
                      schedule.result = `Invalid ${field} input of ${value}. Must be in HH:mm:ss format.`;
                    }
                  }

                  if (constraint?.pattern) {
                    if (!constraint.pattern.test(value)) {
                      schedule.imported = false;
                      schedule.result = `Invalid ${field} input of ${value}. Must be in ${getBlockName(schedule.dayOfWeek, schedule.startTime)} [dayOfWeek][startTime]`;
                    }
                  }
                } else {
                  schedule.imported = false;
                  schedule.result = `Invalid ${field} input of ${value}. Must be a string/text`;
                }
              } else {
                if (!fieldConstraints[field].optional) {
                  schedule.imported = false;
                  schedule.result = `${field} is a required field and must not be empty.`
                }
              }
              break;
            case 'boolean':
              if (value) {
                if (typeof value !== 'boolean') {
                  schedule.imported = false;
                  schedule.result = `Invalid ${field} input of ${value}. Must be a boolean value (true or false)`;
                }
              } else {
                if (!fieldConstraints[field].optional) {
                  schedule.imported = false;
                  schedule.result = `Invalid ${field} input of ${value}. Must be a string`;
                }
              }
              break;
            default:
              if (!schedule[field]) {
                if (!fieldConstraints[field]?.optional) {
                  schedule.result = `${field} is a required field and must not be empty.`
                  schedule.imported = false;
                }
              }
              break;
          }
        }
      })
    })

    data.map((schedule) => {
      // if (schedule.import) {
      const sameDayBlocks = data.filter((o) => o.dayOfWeek === schedule.dayOfWeek && o.id !== schedule.id)
      const endTimeValue =
        schedule.endTime === '00:00:00' && schedule.startTime === '00:00:00'
          ? '24:00:00'
          : schedule.endTime;
      if (!isOverlap) {
        const overlapDay = sameDayBlocks.filter((block) => (block.startTime <= schedule.startTime && block.endTime > schedule.startTime)
          || (block.startTime >= schedule.startTime && block.startTime < endTimeValue)
          || (block.startTime >= schedule.startTime && schedule.startTime > endTimeValue) && (block.dayOfWeek === schedule.dayOfWeek && block.id !== schedule.id))
        if (overlapDay.length > 0) {
          schedule.imported = false;
          schedule.result = `Failed to import overlapping schedules.`
        }
      }
      if (schedule.titleGroupName) {
        const titleGroupInfo = retrieveTitleGroups(schedule);
        if (titleGroupInfo) {
          schedule.titleGroupID = titleGroupInfo.id
          schedule.titleGroupName = titleGroupInfo.name
        } else {
          schedule.imported = false;
          schedule.result = `Imported title group name does not exist.`
        }
      }
      if (schedule.seriesName) {
        const seriesInfo = retrieveSeries(schedule);

        if (seriesInfo) {
          schedule.seriesID = seriesInfo.id
          schedule.seriesName = seriesInfo.name
        } else {
          schedule.imported = false;
          schedule.result = `Imported series name does not exist.`
        }
      }
      // }
    })
    return data;
  }
  // Time deconstruction function of strings in the format of HH:MM:SS
  const deconstructTimeString = (time) => {
    // if minutes is 59 then add 1 on hour
    if (time.split(':')[1] === '59') {
      const hour = parseInt(time.split(':')[0]) + 1;
      return [hour.toString().length === 1 ? `0${hour.toString()}` : hour.toString(), '00', '00'];
    } else
      return time.split(':');
  };

  // Helper function to convert decimal time to HH:mm:ss format
  const convertToTime = (decimalTime) => {
    const duration = moment.duration(Math.floor(decimalTime * 86400000), 'milliseconds');
    const toTimeString = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
    // hour must have 00 digits
    const hour = deconstructTimeString(toTimeString)[0];
    const minute = deconstructTimeString(toTimeString)[1];
    const second = deconstructTimeString(toTimeString)[2];
    return `${hour}:${minute}:${second} `;
  };

  const removeImportAndResultFields = (data) => {
    let temp = [...data];
    let removedFailedImports = temp.filter((schedule) => schedule.imported)
    const cleanedData = removedFailedImports.map((schedule) => {
      schedule = Object.keys(schedule).reduce((object, key) => {
        if (key !== 'imported' && key !== 'result') {
          object[key] = schedule[key];
        }
        return object;
      }, {});
      return schedule;
    })
    return cleanedData;
  }
  const reconstructBlockName = (blockName) => {
    if (blockName?.length > 0) {
      const deconstructedTime = blockName.split(":");
      // if blockname contains 00:00:00 format instead of 00:00
      if (deconstructedTime && deconstructedTime.length === 3) {
        let temp = `${deconstructedTime[0]}:${deconstructedTime[1]}`
        return `${temp.slice(0, 3)}${temp.slice(-5)}`;
      } else {
        // From Mon108:00 to Mon08:00
        //Ensures that only the day and time is returned
        const removedWeek = `${blockName.slice(0, 3)}${blockName.slice(-5)}`
        return removedWeek;
      }
    } else return blockName
  }

  const constructData = React.useCallback((schedule, index) => {
    let tempSchedule = {};
    switch (architectureTab) {
      case 'Program':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime,
          endTime: schedule.EndTime,
          type: schedule?.Type,
          blockReference: reconstructBlockName(schedule?.BlockReference),
          genre: schedule?.Genre,
          maxCount: schedule?.MaxCount,
          availableDuration: schedule?.AvailableDuration,
          seriesID: schedule?.SeriesId,
          seriesName: schedule?.SeriesName,
          seasonID: schedule?.SeasonNo,
          titleGroupID: schedule?.TitleGroupID,
          titleGroupName: schedule.TitleGroupName,
          layout: schedule?.Layout,
          sequential: schedule?.Sequential,
          link: schedule?.Link,
          week: 1,
          blockName: reconstructBlockName(schedule?.BlockName),
          imported: schedule?.imported === undefined ? true : schedule.imported,
          result: schedule?.result ? schedule.result : "Success",
        }
        break;
      case 'Grading':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime,
          endTime: schedule.EndTime,
          grade: schedule.Value,
          value: 0,
          minutage: 0,
          imported: schedule?.imported === undefined ? true : schedule.imported,
          result: schedule?.result ? schedule.result : "Success",
        }
        break;
      case 'Minutage':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime,
          endTime: schedule.EndTime,
          grade: 0,
          value: 0,
          minutage: parseFloat(schedule.Value),
          imported: schedule?.imported === undefined ? true : schedule.imported,
          result: schedule?.result ? schedule.result : "Success",
        }
        break;

    }
    return tempSchedule;
  }, [architectureTab, channelId, period])

  const formatTimeToHMS = (time) => {
    let formattedTime = time;
    if (typeof time === 'string') {
      if (!(time.split(":").length > 2))
        formattedTime = `${time}:00`;
    } else {
      formattedTime = convertToTime(time);
    }
    if (time === 0) {
      formattedTime = "00:00:00"
    }
    return formattedTime.trim();
  }

  const uploadFile = async (file, fileExtension, overwrite) => {
    setIsLoading(true);
    const boolValues = {
      'TRUE': true,
      'FALSE': false,
      'true': true,
      'false': false
    }
    const reader = new FileReader();
    // Extract the data from the file
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      //Validate the file content
      if (!validateImportedFile(jsonData, importResultColumns)) {
        setErrorMessage(
          'Invalid file format. Please use files in the suggested format above (see sample template)'
        );
        setConfirmModal(false);
        setIsDirty(false);
        setUploadSuccess(false);
        setIsLoading(false);
        return;
      }
      const importedData = jsonData.map((schedule, index) => {
        const timePattern = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

        if (schedule.StartTime
          || schedule.StartTime === 0) {
          schedule.StartTime = formatTimeToHMS(schedule.StartTime, schedule)
          if (!timePattern.test(schedule.StartTime)) {
            schedule.imported = false;
            schedule.result = `Invalid start time input of ${schedule.StartTime}. Must be in HH:mm:ss format.`
          }
        } else {
          schedule.imported = false;
          schedule.result = `Invalid start time input of ${schedule.StartTime}. Must be in HH:mm:ss format.`
        }

        if (schedule.EndTime
          || schedule.EndTime === 0) {
          schedule.EndTime = formatTimeToHMS(schedule.EndTime, schedule)
          if (!timePattern.test(schedule.EndTime)) {
            schedule.imported = false;
            schedule.result = `Invalid end time input of ${schedule.EndTime}. Must be in HH:mm:ss format.`
          }
        } else {
          schedule.imported = false;
          schedule.result = `Invalid end time input of ${schedule.EndTime}. Must be in HH:mm:ss format.`
        }
        if (schedule.Sequential && typeof schedule.Sequential !== 'boolean') {
          schedule.Sequential = boolValues[schedule.Sequential]
        }
        if (schedule.Link && typeof schedule.Link !== 'boolean') {
          schedule.Link = boolValues[schedule.Link]
        }
        if (schedule.Value && architectureTab === "Minutage")
          schedule.Minutage = parseFloat(schedule.Minutage)
        if (typeof schedule.DayOfWeek === 'string')
          schedule.DayOfWeek = Number(schedule.DayOfWeek)

        schedule.BlockName = getBlockName(schedule.DayOfWeek, schedule.StartTime)
        return constructData(schedule, index + 1);
      });
      // Validate the row values for the import result modal
      const validatedImportedData = validateRows(importedData);
      // Once validated, set the data to the state
      if (validatedImportedData.length) {
        if ((schedules?.length > 0) && !overwrite) {
          setIsDirty(false);
          setUploadedList();
          setTimeout(() => {
            setIsLoading(false);
          }, [1200])
          setConfirmModal(true);
        } else {
          setIsDirty(true);
          setUploadedList(validatedImportedData);
          setConfirmModal(false);
          setTimeout(() => {
            setSchedules(removeImportAndResultFields(validatedImportedData));
            setUploadSuccess(true);
            setIsLoading(false);
          }, [3400])
        }
      }
    }

    reader.readAsArrayBuffer(file);
  };

  const handleOnImportFile = () => {
    const acceptableFiles = ['xlsx', 'xls', 'csv'];
    const fileExtension = selectedFile.name.split('.').pop();
    if (acceptableFiles.includes(fileExtension)) {
      if (scheduledata.length > 0) setConfirmModal(true);
      else uploadFile(selectedFile, fileExtension, false);
    } else {
      setErrorMessage(
        'Uploaded file type is not supported. Please use files in the following format (xlsx, csv)'
      );
    }
  };

  const handleOverwrite = () => {
    const fileExtension = selectedFile.name.split('.').pop();
    uploadFile(selectedFile, fileExtension, true);
  };

  return isLoading ? (
    <ImportLoadingModalComponent headerComponent={loadingHeaderComponent} />
  ) : uploadSuccess ? (
    <ImportResultModalComponent
      uploadedList={uploadedList}
      closeModal={closeModal}
      scheduleInfo={scheduleInfo}
      importResultColumns={importResultColumns}
      importResultFileTitle={importResultFileTitle}
    />
  ) : confirmModal ? (
    <ImportConfirmationModalComponent
      show={confirmModal}
      onHide={closeModal}
      onProceed={handleOverwrite}
      message="A list already exists for this channel. Importing overwrites all existing data for this channel."
    />
  ) : (
    <ImportFileModalComponent
      closeModal={closeModal}
      selectedFile={selectedFile}
      setSelectedFile={(e) => {
        setSelectedFile(e);
        setErrorMessage('');
      }}
      handleOnImportFile={handleOnImportFile}
      errorMessage={errorMessage}
      setErrorMessage={(e) => setErrorMessage(e)}
      sampleTemplate={sampleTemplate}
    />
  );
}
