import moment from 'moment';
import { calculateDates, setSecondsToZero } from 'utils';
import { getBlockName } from '../utils/architecture.utils';

export const getMondayZeroToSixSchedules = (
    schedules,
    { startDate, endDate },
    isBroadcast
) => {
    let schedulesOverlappingSix = [];
    // Function that checks times between 00:00:00 and 06:00:00
    const checkTime = (time) => {
        const hours = time.getHours();
        return hours >= 0 && hours < 6;
    };

    // Time deconstruction function of strings in the format of HH:MM:SS
    const deconstructTimeString = (time) => {
        return time.split(':');
    };

    // Return schedules if empty or is not broadcast
    if (!schedules && schedules.length === 0) return schedules;
    if (!isBroadcast) return schedules.filter((o) => o.week === 1);

    // Deep copy schedules
    let copySchedules = JSON.parse(JSON.stringify(schedules));

    // Identify all monday schedules before six or overlaps before and after six
    const findSchedulesBeforeSix = (block) => {

        // Ensure there are no seconds in both start and end time
        const startInZeroSeconds = moment(
            setSecondsToZero(moment(block.startTime, "HH:mm:ss")
                .toDate())).format("HH:mm:ss")
        const endInZeroSeconds = moment(
            setSecondsToZero(moment(block.endTime, "HH:mm:ss")
                .toDate())).format("HH:mm:ss")

        // Get start and end time as dates
        const { startTime, endTime } = calculateDates(
            block.dayOfWeek,
            block.week,
            block.startTime,
            block.endTime,
            { startDate, endDate });
        const endTimeHour = moment(endTime).toDate().getHours() === 0 ? 24 : moment(endTime).toDate().getHours()
        const isOverlappingSix = startInZeroSeconds === endInZeroSeconds
            || (moment(startTime).toDate().getHours() < 6
                && moment(endTime).toDate().getHours() > 6)

        //Validate if start and end times overlap before and after six
        if (isOverlappingSix) {
            schedulesOverlappingSix.push(block);
        }
        // not beyond 6
        return checkTime(startTime) && endTimeHour <= 6 && !(startInZeroSeconds === endInZeroSeconds)
    }

    const moveSchedulesToWeekTwo = (events) => {
        let toWeekTwoMonday = [...events];
        if (toWeekTwoMonday) {
            toWeekTwoMonday.forEach((event) => {
                event.id = crypto.randomUUID();
                event.week = 2;
                event.blockName = getBlockName(event.dayOfWeek, event.startTime);
            })
        }
        return toWeekTwoMonday;
    }

    const week1Schedules = copySchedules[0]?.week
        ? copySchedules.filter(item => item.week === 1)
        : copySchedules.map(item => {
            return { ...item, week: 1 }
        });
    const schedulesToMove = week1Schedules.filter(event => {
        // Get all schedules on day 1/Monday
        if (event.dayOfWeek === 1) {
            if (findSchedulesBeforeSix(event)) {
                return event;
            }
        }
    })
    let result = schedules[0]?.week ? schedules.filter(o => o.week === 1) : schedules
    return [
        ...result,
        ...moveSchedulesToWeekTwo(schedulesToMove),
        ...moveSchedulesToWeekTwo(schedulesOverlappingSix)
    ];
};