import { getRowsToDisplay } from "../helpers";

export const onFilterChangedEvent = ({ gridRef, onFilterChanged, params }) => {
  const rowsToDisplay = getRowsToDisplay(params);

  if(!rowsToDisplay.length) {
    gridRef.current.api.showNoRowsOverlay();
  }else {
    gridRef.current.api.hideOverlay();
  }
  
  if (onFilterChanged) onFilterChanged(params);

  gridRef.current.api.refreshCells({ force: true });
}