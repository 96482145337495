import * as React from 'react';
import PropTypes from 'prop-types';
import { closeIcon, magnifyingGlassSmIcon } from 'assets';
import styles from './OpSearchbar.module.css';

export const OpSearchbar = ({
  searchFilter = null,
  searchOptions,
  smallInput,
  width,
  onOptionSelected,
  onSearch,
  onSearchCleared,
}) => {

    const inputRef = React.useRef(null);
    const searchRef = React.useRef(null);

    const [appliedFilter, setAppliedFilter] = React.useState(null);
    const [saveSearchKey, setSaveSearchKey] = React.useState('');
    const [searchKey, setSearchKey] = React.useState('');
    const [showClearSearch, setShowClearSearch] = React.useState(false);
    const [showSearchOptions, setShowSearchOptions] = React.useState(false);
    
    const isSearchMode = React.useMemo(() => (saveSearchKey.length ? true : false), [saveSearchKey]);

    const onChangeSearchKey = (e) => {
      setSearchKey(e.target.value);
      setShowClearSearch(false);
    };

    const handleOnSearch = () => {
      setSaveSearchKey(searchKey);
      onSearch(searchKey);
      const hasSearchKey = searchKey.length;
      setShowClearSearch(hasSearchKey);
      setShowSearchOptions(false);
    };

    const onClearSearch = () => {
      setSaveSearchKey('');
      setSearchKey('');
      onSearch('');
      setShowClearSearch(false);
      setAppliedFilter(null);
      if (onSearchCleared) onSearchCleared();
    };
    
    const triggerOnClearSearch = (e) => {
      if (e.key === 'Enter') onClearSearch();
    };

    const onSearchOptionSelected = (option) => {
      if (!onOptionSelected) return;

      onOptionSelected(option);
    }

    const triggerOnSearch = React.useCallback((e) => {
      if (e.key === 'Enter') {
        if (appliedFilter) {
          const newParameter = {...appliedFilter, searchKey: e.target.value}
          setAppliedFilter(newParameter);
          onSearchOptionSelected(newParameter);
          handleOnSearch();
          if (inputRef.current) {
            inputRef.current.blur(); // Remove focus
          }
        }
      };
    }, [appliedFilter, searchKey]);

    const modifiedSearchFilterOptions = React.useCallback((value) => (
      <span>
        Search <strong>{value}</strong> for: {searchKey}
      </span>
    ), [searchKey]);

    React.useEffect(() => {
      const backdropClicked = (e) => {
        if (!searchRef.current?.contains(e.target)) {
          setSearchKey(saveSearchKey);
          const shouldHideClearSearch = saveSearchKey?.length > 0;
          setShowClearSearch(shouldHideClearSearch);
        }
      };

      document.addEventListener('mousedown', backdropClicked);
      return () => document.removeEventListener('mousedown', backdropClicked);
    }, [searchRef, saveSearchKey]);

    React.useEffect(() => {
      if (appliedFilter) {
        setSearchKey(appliedFilter.searchKey);
        setSaveSearchKey(appliedFilter.searchKey);
      } else {
        setSearchKey('');
        setSaveSearchKey('');
      }
    }, [appliedFilter]);

    React.useEffect(() => {
      if (searchFilter) {
        setSearchKey(searchFilter.searchKey);
        setAppliedFilter(searchFilter);
        handleOnSearch();
      };
    }, [searchFilter])

    return (
      <React.Fragment>
        <div
          className='d-flex'
          ref={searchRef}
          style={{
            width: width || '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {isSearchMode ? (
            <div className={styles.selectedSearchOptionContainer}>
              <span className={styles.selectedSearchOption}>
                {appliedFilter?.value}
              </span>
            </div>
          ) : null}
        
          <input
            className={`${isSearchMode ? styles.searchInputWithFilter : styles.searchInput} ${smallInput ? styles.smallInput : ''}`}
            onChange={onChangeSearchKey}
            onFocus={() => setShowSearchOptions(true)}
            onKeyDown={triggerOnSearch}
            placeholder="Search"
            ref={inputRef}
            style={{ textIndent: '5px' }}
            type="text"
            value={searchKey}
          />

          {/* Icon wrapper */}
          <div className={`${styles.searchIconWrapper} ${smallInput ? styles.smallInput : ''}`}>
            <img
              alt="CloseIcon"
              className={styles.closeIcon}
              hidden={!appliedFilter}
              onClick={onClearSearch}
              onKeyDown={triggerOnClearSearch}
              src={closeIcon}
              tabIndex={0}
            />
            <img
              alt="SearchIcon"
              className={styles.searchIcon}
              onClick={handleOnSearch}
              onKeyDown={triggerOnSearch}
              src={magnifyingGlassSmIcon}
              style={{ pointerEvents: !searchKey?.length || !appliedFilter ? 'none' : null }}
              tabIndex={0}
            />
          </div>
        </div>
        {searchOptions && searchOptions.length ? (
          <div>
            {showSearchOptions && searchKey?.length && !appliedFilter ? (
              <div className={styles.searchFilterOptionContainer}>
                {searchOptions.map((attr, index) => (
                  <div
                    className={styles.searchFilterOption}
                    key={`${attr.id}${index}`}
                    onMouseDown={() => {
                      const searchParameter = {...attr, searchKey};
                      setAppliedFilter(searchParameter);
                      onSearchOptionSelected(searchParameter);
                      handleOnSearch();
                    }}
                  >
                    {modifiedSearchFilterOptions(attr.value)}
                  </div>
                ))}
              </div> 
            ) : null}
          </div>
        ): null}
      </React.Fragment>
    );
};

OpSearchbar.propTypes = {
  width: PropTypes.string,
  smallInput: PropTypes.bool,
  searchOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null]),
  ]),
  onOptionSelected: PropTypes.func,
  onSearch: PropTypes.func,
};

OpSearchbar.defaultProps = {
  width: '',
  smallInput: false,
  searchOptions: null,
  onOptionSelected: () => null,
  onSearch: () => null,
};