import { HttpMethods, APIContexts } from 'enums';
import axios from 'axios';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.TITLE_EXCLUSION}/`;

export const GetTitleExclusionProgrammeTitlesAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitleExclusionProgrammeTitles';
  const endpoint = `${apiHost}${apiContext}${api}?page=1&size=10000&titleIds=${queryParams.titleIds}&channelId=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleExclusionsAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetTitleExclusions';
  const endpoint = `${apiHost}${apiContext}${api}?channelID=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleExclusionTitleDetailsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'getTitleExclusionTitleDetails';
  const endpoint = `${apiHost}${apiContext}${api}?titleId=${queryParams.titleId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleGroupExclusionsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitleGroupExclusions';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelID}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const SaveTitleGroupExclusionsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'SaveTitleGroupExclusion';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams.payload,
    method: HttpMethods.POST,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetInterstitialExclusionsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetInterstitialExclusions';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelID}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const SaveInterstitialExclusionsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'SaveInterstitialExclusion';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams.payload,
    method: HttpMethods.POST,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetSeasonExclusionsAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetSeasonExclusions';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelID}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const SaveSeasonExclusionsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'SaveSeasonExclusion';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    payload: queryParams.payload,
    method: HttpMethods.POST,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ImportGroupsToExcludeAPI = ({
  file,
  fileExtension,
  channelID,
  isOverride,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportGroupsToExclude';
  const url = `${apiHost}${apiContext}${api}?fileExtension=${fileExtension}&channelId=${channelID}&isOverride=${isOverride}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const ImportSeasonsToExcludeAPI = ({
  file,
  fileExtension,
  channelID,
  isOverride,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportSeasonsToExclude';
  const url = `${apiHost}${apiContext}${api}?fileExtension=${fileExtension}&channelId=${channelID}&isOverride=${isOverride}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const ImportInterstitialsToExcludeAPI = ({
  file,
  fileExtension,
  channelID,
  isOverride,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportInterstitialsToExclude';
  const url = `${apiHost}${apiContext}${api}?fileExtension=${fileExtension}&channelId=${channelID}&isOverride=${isOverride}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
