import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

const useNotificationStore = createWithEqualityFn((set) => ({
  message: '',
  onConfirmCallback: null,
  showNotification: false,
  danger: (data) => set(() => ({ message: data, showNotification: true })),
  success: (data) => set(() => ({ message: data, showNotification: true })),
  handleConfirm: () => set(() => ({ message: '', showNotification: false, onConfirmCallback: null })),
  dangerWithCallback: (data) => set(() => ({ message: data.message, showNotification: true, onConfirmCallback: data.callback})),
  successWithCallback: (data) => set(() => ({ message: data.message, showNotification: true, onConfirmCallback: data.callback})),
}));

const useNotification = (callback) => useNotificationStore(callback, shallow);

export default useNotification;
