import React from 'react'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { plusCircleIcon } from '../../assets';
import './Button.css';

export const Button = ({
    onClick,
    label,
    icon,
    iconPosition = 'right', //Left || Right
    style,
    disabled = false,
    allowTextWrap = false,
    invertIconColor = false,
    children
}) => {

    return (
        <ButtonComponent
            onClick={onClick}
            disabled={disabled}
            iconPosition={iconPosition}
            cssClass='e-custom-button'
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                flexDirection: iconPosition === 'right' ? 'row' : 'row-reverse',
                gap: '10px',
            }}
        >
            <span style={{ whiteSpace: allowTextWrap ? 'normal' : 'nowrap' }}>
                {label}
            </span>
            {
                icon && <img
                    src={icon}
                    alt={label}
                    className='icon'
                    style={{
                        color: '#FFFFFF',
                        width: '14px',
                        height: '14px',
                        filter: invertIconColor ? 'invert(100%) brightness(200%) contrast(200%)' : 'none', //invert colors from black to white
                    }}
                />
            }
            {children && children}
        </ButtonComponent >
    )
}