export const ScrollPatcherTemplate = ({ gridHeaderHeight, isVisible, theme }) => {
  return isVisible && (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2,
        height: `${gridHeaderHeight}px`,
        width: '15px',
        borderRadius: '0 6px 0 0',
        borderLeft: '1px solid var(--op2mise-color-charcoal-light)',
        backgroundColor: theme === 'PRIMARY' ? 'var(--op2mise-color-primary)' : 'var(--op2mise-color-white)',
      }}
    ></div>
  )
}