import { memo } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './OpDatepicker.css';

export const OpDatepicker = memo((props) => {
  const {
    allowEdit = false,
    cssClass,
    format,
    label,
    showClearButton = false,
    value,
    min,
    max,
    onChange,
    enabled,
    ...rest
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        marginRight: '10px',
        width: '100%'
      }}
    >
      {label && (
        <div
          style={{
            fontSize: '14px',
            color: '#6D6D73',
            marginBottom: '5px',
            marginRight: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </div>
      )}
      <div className="op-date-picker-wrapper">
        <DatePickerComponent
          value={value}
          format={format}
          showClearButton={showClearButton}
          allowEdit={allowEdit}
          strictMode={true}
          min={min}
          max={max}
          onChange={onChange}
          cssClass={cssClass}
          enabled={enabled}
          {...rest}
        />
      </div>
    </div>
  );
}
);
