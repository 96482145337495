import React from 'react';
import { Navigate } from 'react-router-dom';

import SubmittedScheduleFullComponent from '../components/submission/submitted-schedule/SubmittedScheduleFullComponent';
import GeneratedScheduleFullComponent from '../components/schedule/generated-schedule/GeneratedScheduleFullComponent';
import EmailTriggerComponent from '../components/email-trigger/EmailTriggerComponent';

import ChannelArchitecture from '../components/architecture/ChannelArchitecture';
import BreakPatterns from '../components/break-patterns/BreakPatterns';
import Playlist from 'Playlist/Playlist';

const AccessDeniedComponent = React.lazy(() =>
  import('../components/_error-pages/access-denied/AccessDeniedComponent')
);
const HomeComponent = React.lazy(() =>
  import('../components/home/HomeComponent')
);
const InternalServerComponent = React.lazy(() =>
  import('../components/_error-pages/internal-server/InternalServerComponent')
);
const NotFoundComponent = React.lazy(() =>
  import('../components/_error-pages/not-found/NotFoundComponent')
);
const MaintenanceComponent = React.lazy(() =>
  import('../components/maintenance/MaintenanceComponent')
);
const ChannelProfileComponent = React.lazy(() =>
  import('../components/channel-profiles/ChannelProfileComponent')
);
const PrioritisationComponent = React.lazy(() =>
  import('../components/prioritisation/PrioritisationComponent')
);
const ScheduleComponent = React.lazy(() =>
  import('../components/pages/scheduler/ScheduleComponent')
);
const SubmissionComponent = React.lazy(() =>
  import('../components/submission/SubmissionComponent')
);
const TitleGroupComponent = React.lazy(() =>
  import('../components/title-group/TitleGroupComponent')
);
const HelpComponent = React.lazy(() =>
  import('../components/help/HelpComponent')
);
const UserProfileComponent = React.lazy(() =>
  import('../components/profile/UserProfileComponent')
);
const ScheduleDetailsComponent = React.lazy(() =>
  import('../components/schedule/schedule-details/ScheduleDetailsComponent')
);
const ChannelComponent = React.lazy(() =>
  import('../components/channel/ChannelComponent')
);
const RequestTimeoutComponent = React.lazy(() =>
  import('../components/_error-pages/request-timeout/RequestTimeoutComponent')
);
const ChannelGroupComponent = React.lazy(() =>
  import('../components/channel-group/ChannelGroupComponent')
);
const UserComponent = React.lazy(() =>
  import('../components/user/UserComponent')
);
const OrganisationComponent = React.lazy(() =>
  import('../components/organisation/OrganisationComponent')
);
const TitleExclusionComponent = React.lazy(() =>
  import('../components/title-exclusion/TitleExclusionComponent')
);
const AttributesOverrideComponent = React.lazy(() =>
  import('../components/attributes-override/AttributesOverrideComponent')
);
const SchedulerComponent = React.lazy(() =>
  import('../components/schedule/react-scheduler/SchedulerComponent')
);

const SubmittedScheduleComponent = React.lazy(() =>
  import('../components/submission/scheduler/SubmissionSchedulerComponent')
);

const ViewScheduleComponent = React.lazy(() =>
  import('../components/view-schedule/ViewSchedulesComponent')
);

const LoadingComponent = React.lazy(() =>
  import('../components/_reusable/loading/LoadingComponent')
);

const InclusionComponent = React.lazy(() =>
  import('../components/inclusion/InclusionComponent')
);

const ProgramLibraryComponent = React.lazy(() =>
  import('../components/library/Programs/ProgramsComponent')
);

const RightsComponent = React.lazy(() => (
  import('../components/rights/RightsComponent')
))

class ViewsRouter {
  routes = [
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/`,
      element: <HomeComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/architecture`,
      element: <ChannelArchitecture />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/break-patterns`,
      element: <BreakPatterns />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/channel-profiles`,
      element: <ChannelProfileComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/not-found`,
      element: <NotFoundComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/access-denied`,
      element: <AccessDeniedComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/internal-server/:message`,
      element: <InternalServerComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/prioritisation`,
      element: <PrioritisationComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/title-groups`,
      element: <TitleGroupComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/view-schedules`,
      element: <ViewScheduleComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/schedule`,
      element: <ScheduleComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/scheduledetails`,
      element: <ScheduleDetailsComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/submission`,
      element: <SubmissionComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/email-triggers`,
      element: <EmailTriggerComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/help`,
      element: <HelpComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/maintenance`,
      element: <MaintenanceComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/request-timed-out`,
      element: <RequestTimeoutComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/generatedschedulecalendar`,
      element: <GeneratedScheduleFullComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/submittedschedules`,
      element: <SubmittedScheduleFullComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/channels`,
      element: <ChannelComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/channel-groups`,
      element: <ChannelGroupComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/organisation`,
      element: <OrganisationComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/title-exclusions`,
      element: <TitleExclusionComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/attributes-override`,
      element: <AttributesOverrideComponent />,
    },
    {
      path: '*',
      element: <Navigate to={`${process.env.REACT_APP_SUBFOLDER}/not-found`} />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/login`,
      element: <LoadingComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/scheduler`,
      element: <SchedulerComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/submission-scheduler`,
      element: <SubmittedScheduleComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/playlist`,
      element: <Playlist />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/inclusion`,
      element: <InclusionComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/library/programs`,
      element: <ProgramLibraryComponent />,
    },
    {
      path: `${process.env.REACT_APP_SUBFOLDER}/rights`,
      element: <RightsComponent />,
    },
  ];
}

const viewsRouter = new ViewsRouter();
export default viewsRouter;
